import React from "react";
import { useMemo, useCallback, useState } from "react";
import useClickAway from "../useClickAway";
import {DotLoader
} from "react-spinners/DotLoader";


import {
  deleteDoc,
    getDoc,
    getDocs,
    doc,
    collection, 
    
  } from "firebase/firestore";
  import { db } from "../firebase.config";
  import { useParams, useNavigate } from 'react-router-dom';
import LoadingScreen from "./LoadingScreen";
import Counter from './Counter'
import {  useCallState } from "../CallProvider";
import { SPEAKER, LISTENER, MOD } from "../App";
import CopyLinkBox from "./CopyLinkBox.jsx";
import Participant from "./Participant";
import Audio from "./Audio";
import Menu from "./Menu";
import MicIcon from "./MicIcon";
import MutedIcon from "./MutedIcon";
import { useEffect } from "react";
import VotingModal from './VotingModal';
import MoreIcon from "./MoreIcon";
import { UserAuth } from "../contexts/AuthContext";
import {BiPoll} from 'react-icons/bi'


 const InCall = () => {

  const { id: roomId } = useParams();

  

    const roomref = doc(db, "rooms", roomId )
    const participantsRef = collection(roomref, 'participants');
    const userData = UserAuth();
  
    const [ParticipantsData, setParticipantsData] = useState([]);


    const ControlBarIcon = ({ icon, text = 'tooltip 💡' }) => (
      <div className="sidebar-icon group">
        {icon}
        <span class="sidebar-tooltip group-hover:scale-100">
          {text}
        </span>
      </div>
    );




    useEffect(() => {
      const getAllParticipants = async () => {
          const data = await getDocs(participantsRef);
          setParticipantsData(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
          
      };

      getAllParticipants();
      
  }, [participantsRef]);

  
   
  
    
    

 
    const navigate = useNavigate();
    const [room, setRoom] = useState();
    const { ref, isVisible, setIsVisible } = useClickAway(false);
    
//Note it should be deletedoc when the host leaves the room.
    const handleManualLeave = () => {
        navigate('/rooms');
        deleteDoc(roomref);
        
        
    };

    



  const {
    participants,
    getSpeakerType,
    isloading,
    changeSpeakerType,
    activeSpeakerId,
    getAccountType,
    getParticipantData,
    deleteDocument,
    getMembersByIds,
    leaveCall,
    handleMute,
    handleUnmute,
    raiseHand,
    lowerHand,
    endCall,
  } = useCallState();
  

  
  const [showModal, setShowModal] = useState(false);
  

//The code below is a modal that should pop-up when a participant is made a speaker and to let them decide whether they are opposing or proposing?//Now with the new code I implememted the handleProposer and handle Opposer should set a boolean value on fireastore corresponding withthe users id(remember to do this)



/*
function handleProposer () {
  let newValue = true
  changeSpeakerType(roomId, participants?.user_id, true);
  setisProposer(true);



}
function handleOppposer () {
  let newValue = false
  changeSpeakerType(roomId, participants?.user_id, false);
  setisProposer(false);
  
};

  const SpeakerModal = ({ onClose }) => {

    return(

    <div className="flex flex-col  items-center bg-none rounded-lg border-[1px] border-black align-middle font-extrabold  shadow-md justify-center" > 

      <button onClick={onClose} className="absolute right-[3px] top-[8px] bg-none">Close</button>

      <h2>Yay you're a speaker!</h2>
      <h3>Are you proposing or opposing the motion?</h3>
      <div className="flex  ">
        <button className=" m-1 p-[9px] rounded-[20px]  border-[1px]  border-black shadow-lg font-bold text-lg text-white bg-black hover:text-xl transition-all" onClick={handleProposer}>Proposing</button>


        <button className="m-1 border-[1px] p-[9px] rounded-[20px] border-black shadow-lg font-bold text-lg text-white bg-black hover:text-xl transition-all"onClick={handleOppposer}>Opposing</button>
       
      </div>
     
    
    </div>
    );
}

*/
const menuOptions = useMemo(() => {
  
  let options = [];

  options.push({
    text: 'Toogle Poll',
    action: () => setShowModal(true),
  });
  

  return options;


},[]
) ; 

  const local = useMemo(
    (p) => participants?.filter((p) => p?.local)[0],
    [participants]
  );
//im about to remove the owner? part because idk what it does
const mods = useMemo(
  () =>
  ParticipantsData?.filter((p)=> p?.role === "MOD"),
  

  [ParticipantsData]
);
const idsFromFirestore = mods.map((doc) => doc.id);

const participantids = participants.map((doc) => doc.user_id);


 const moderators = getMembersByIds(participants, idsFromFirestore);

 
  

  

  
  

  const listeners = useMemo(() => {
    const lst = ParticipantsData
      ?.filter(  (p) => p?.role === "LST")
      .sort((a, _) => {
        // Move raised hands to front of list
/*
        if (a?.user_name.includes("✋")) return -1;
        return 0;
        */
      });
      const ids = lst.map((doc) => doc.id);
      const filteredList = getMembersByIds(participants, ids)
      const l = [...filteredList,...moderators]
    return (
      <div className="mt-[24px] flex flex-wrap">
        
        
        {l?.map((p, i) => (
          
          <Participant
            roomId={roomId}
            participant={p}
            key={`listening-${p?.user_id}`}
            local={local}
            modCount={mods?.length}
          />
        ))}
      </div>
    ); 
  }, [participants, ParticipantsData, roomId, getMembersByIds, local, mods]);

 

/*
 {isProposer ? (
        <div className="bg-green-500">
          { Render content for proposer}
          </div>
          ) : (
            <div className="bg-red-500">
              { Render content for opposer}
            </div>
          )}



useEffect(() => {
const showModalCondition = ()=>{
  if(getAccountType(local.user_name)==SPEAKER){
    setShowModal(true);
  }
}
})
*/


const canSpeak = useMemo(() => {

//  const s = [...speakers,...moderators];


  const Proposers = ParticipantsData
  ?.filter((p) => p?.role === SPEAKER && p.isProposer === true && p?.id !== activeSpeakerId);

  const ids = Proposers.map((doc) => doc.id);
  const p = getMembersByIds(participants, ids)

  

  
    return (
      <div className="mb-[24px] right-0 flex place-content-start  ">
        {p.map((p, i) => (
          <Participant 
          
            roomId={roomId}
            participant={p}
            key={`speaking-${p.user_id}`}
            local={local}
            modCount={mods.length}
          />
        ))}
      </div>
    );
  
}, [mods, getMembersByIds, roomId, local]);

const canSpeak_Op = useMemo(() => {
  

  const Proposers = ParticipantsData
  ?.filter((p) => p?.isProposer === false && p?.role === SPEAKER && p?.id !== activeSpeakerId)

  const ids = Proposers.map((doc) => doc.id);
  const op = getMembersByIds(participants, ids)


  
    


    return (
      <div className="mb-[24px] right-0 flex  place-content-start  h-[100%]   ">
        {op.map((p, i) => (
          <Participant 
            roomId ={roomId}
            participant={p}
            key={`speaking-${p.user_id}`}
            local={local}
            modCount={mods.length}
          />
        ))}
      </div>
    );
  }, [mods, ,getMembersByIds, roomId, local]);


  const ActiveSpeakerContainer = useMemo(() => {


    
  const activeSpeaker = ParticipantsData
  ?.filter((p) => p?.role === SPEAKER && p?.id === activeSpeakerId)

    
  const ids = activeSpeaker.map((doc) => doc.id);
  const members = getMembersByIds(participants, ids)

   
  
  
  
    
      
  
  
      return (
        <div className="mb-[24px] right-0 flex  place-content-start ">
          {members.map((p, i) => (
            <Participant 
              roomId ={roomId}
              participant={p}
              key={`speaking-${p.user_id}`}
              local={local}
              modCount={mods.length}
            />
          ))}
        </div>
      );
    }, [mods, ,getMembersByIds, roomId, local]);




  const handleAudioChange = useCallback(
    () => (local?.audio ? handleMute(local) : handleUnmute(local)),
    [handleMute, handleUnmute, local]
  );
  const handleHandRaising = useCallback(
    () =>
      local?.user_name.includes("✋") ? lowerHand(local) : raiseHand(local),
    [lowerHand, raiseHand, local]
  );

  
    
    
   
    

    
  
  const handleEndCall=() =>{
    endCall(roomId)
    deleteDoc(roomref);

  }
  
  return (
    <div className="bg-black h-screen pt-16 ">
      {isloading ? (
      <LoadingScreen/>
      ) : (
        <div className="m-[30px] sm:m-[48px] bg-black  ">
          <div className="flex justify-between items-center mt-5 py-3 border-b-2 border-b-white">
            <div className=" h-[200px]">
              <h1 className="font-extrabold text-lg text-white">Proposers</h1>
              {canSpeak}
            </div>
            <div>{ActiveSpeakerContainer}</div>
            
            <div className="h-[200px]">
              <h2 className="font-extrabold text-lg text-white">Opposers</h2>
              {canSpeak_Op}
            </div>
          </div>
          <CopyLinkBox roomId={roomId} />
          <h3 className="font-extrabold text-lg text-white p-3">Listeners</h3>
          

          {listeners}
          <div className="flex justify-center">
         {showModal && <VotingModal roomId ={roomId} onClose={() => setShowModal(false)} />}
         </div>
          <div className="flex justify-center absolute bottom-0 left-0 h-[52px]  w-[100%] bg-grey box-border p-[12px]">
            <div className=" max-w-[700px] flex justify-between w-[100%]">
              {[MOD, SPEAKER].includes(getAccountType(local?.user_name)) ? (
                <button
                  className="sm:mr-auto flex items-center text-lg border-none bg-transparent cursor-pointer rounded-[8px] font-extrabold hover:bg-green"
                  onClick={handleAudioChange}
                >
                  {local?.audio ? (
                    <MicIcon type="simple" />
                  ) : (
                    <MutedIcon type="simple" />
                  )}
                  <span className="ml-[4px] text-white">
                    {local?.audio ? "Mute" : "Unmute"}
                  </span>
                </button>
              ) : (
                <button
                  className="mr-auto text-lg border-none bg-transparent cursor-pointer rounded-[8px] font-extrabold hover:bg-green "
                  onClick={handleHandRaising}
                >
                  <span className="ml-[4px] text-white">
                    {local?.user_name.includes("✋")
                      ? "Lower hand"
                      : "Raise hand ✋"}
                  </span>
                </button>
              )}
             
              {mods?.length < 2 &&
              getAccountType(local?.user_name) === MOD ? (
              <div className="flex justify-center ">
                
                <button
                  className="sm:ml-auto text-lg border-none bg-transparent  cursor-pointer rounded-[8px] font-extrabold hover:bg-green text-white "
                  onClick={handleEndCall}
                >
                  End call
                </button>
                
               
               
              </div>
              
              ) : (
                <button
                  className="ml-auto text-lg border-none bg-transparent  cursor-pointer rounded-[8px] font-extrabold hover:bg-green text-white "
                  onClick={leaveCall}
                >
                  Leave call
                </button>
              )}
            </div>
          </div> 
          <div className="flex w-10 justify-center ">
          <button className="border-none bg-transparent absolute  cursor-pointer text-lg text-white" onClick={() => setIsVisible(!isVisible)}>
                <BiPoll size={30} />
              </button>
              {isVisible && (
                <div ref={ref} className="m-3">
                 <Menu options={menuOptions} setIsVisible={setIsVisible} />
                </div>
      )}</div>
          
        </div>
        
      )}
      <Audio participants={participants} />
    </div>
  );
  

}





export default InCall;