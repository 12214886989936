import React from "react";
import Account from "../components/Account";
import Navbar from "../components/Navbar";






function AccountPage(){
    return(
        <div className="dark:bg-black dark:border-black dark:border-[1px] h-[100%] ">
            
            <Account/>
        </div>
    )
}

export default AccountPage;