import React, { useState } from 'react';
import { MOD } from "../App";
import Daily from "@daily-co/daily-js";
import { FaGlobeAfrica } from 'react-icons/fa'
import { HiLockClosed } from 'react-icons/hi'
import { db } from "../firebase.config";
import {  
    collection,     
    doc,
    setDoc   
} from "firebase/firestore";
import { useCallState } from '../CallProvider';


//Future Max remember to find a way to sync and intergrate the createRoom function with the one in callProvider
import { useNavigate } from 'react-router-dom';
import { UserAuth } from '../contexts/AuthContext';


const AddRoomModal = ({ onClose }) => {



  




    const navigate = useNavigate();
    


    const { joinRoom } = useCallState();
    const  userData = UserAuth();
    const  {user } = UserAuth();
    
   


    
  
    const [roomType, setRoomType] = useState('open');
    const [topic, setTopic] = useState('');
    const [SpeakingTime, setSpeakingTime ] = useState(5);
    
//    const [Room, setRoom] = useState([]);
    const [Error, setError] = useState(null);


    const createRoom = async (roomName) => {
      if (roomName) return roomName;
      const response = await fetch(
        // CHANGE THIS TO YOUR NETLIFY URL
        // EX: https://myapp.netlify.app/.netlify/functions/room
        `${
          process.env.REACT_APP_VERCEL_URL || "http://localhost:8800"
        }/rooms`,
        {
          method: "POST",
          body: JSON.stringify({ properties: { privacy: roomType, eject_at_token_exp: true, exp: 1800 } }),
  
              
        }
        
      ).catch((err) => {
        throw new Error(err);
      });
      const room = await response.json();
      return room;
      
    };
   
    const createToken = async (roomName) => {
   
   
   //here we are adding this const becasuse for some reason im getting" no properties provided" which is weird so im hardcoding it
    


        if (!roomName) {
          setError("Eep! We could not create a token");
        }
        const response = await fetch(
          // CHANGE THIS TO YOUR VERCEL URL
          // EX: https://myapp.netlify.app/.netlify/functions/token
          `${
            process.env.REACT_APP_VERCEL_URL || "http://localhost:8800"
          }/meeting-tokens`,
          {
            method: "POST",
            body: JSON.stringify({ properties:{ room_name: roomName, is_owner: true },
            }),
                
          }
        ).catch((err) => {
          throw new Error(err);
        });
        const result = await response.json();
        return result;
      };
    
    
    const createRoomDoc = async () => {

      const addParticipantToRoom = async (roomId, participantData) => {
        try {
          // Create a reference to the "rooms" collection and the specific room document
          const roomRef = doc(db, 'rooms', roomId);
      
          // Create a reference to the "participants" subcollection within the room document
          const participantsRef = collection(roomRef, 'participants');
      
          // Use the user ID as the document ID for the participant data
          await setDoc(doc(participantsRef, participantData.userId), participantData);
          console.log('Participant added successfully.');
        } catch (error) {
          console.error('Error adding participant:', error);
        }
      };
      
       try{ 
        

        if (!topic) 
           return;
           
        
//           const userCollectionref = collection(db, 'rooms', RoomId)
             
           
           
          
          
          const room = await createRoom();
          
//          setRoom(roomInfo);
          console.log(room.name);
          
          
          const customId = room.name;
          const documentRef = doc(db, 'rooms', customId);

          const roomData = await setDoc(documentRef, {topic:topic}) 

         
          
          













        
          

          

          
           
          
       
          /**
           * When a moderator makes someone else a moderator,
           * they first leave and then rejoin with a token.
           * In that case, we create a token for the new mod here.
           * 
           */
          let roomInfo = { room };
          let newToken;
          
            // create a token for new moderators
            newToken = await createToken(roomInfo?.name);
         
    
          const call = Daily.createCallObject({
            audioSource: true, // start with audio on to get mic permission from user at start
            videoSource: false,
            dailyConfig: {
              experimentalChromeVideoMuteLightOff: true,
            },
          });
          let userName = user && user.displayName 
          
          const options = {
            // CHANGE THIS TO YOUR DAILY DOMAIN
            // EX: https://myaccount.daily.co/${roomInfo?.name}
            url: `${
              process.env.REACT_APP_DAILY_DOMAIN || "https://notion.daily.co"
            }/${roomInfo?.name}`,
            userName,
          };
          if (roomInfo?.token) {
            options.token = roomInfo?.token;
          }
          if (newToken?.token) {
            options.token = newToken.token;
          };
          
           
           let name = room.name;
           userName = `${userName?.trim()}_${MOD}`;
           
           
           
           
           
           joinRoom({userName, name, })
           
           navigate(`room/${room.name}`)
           
           
           
        } 
         catch(error) {
        console.error("Error creating room:", error);
        
        }
    }

/*


*/
   

/*
  const createUser = async () => {
    await addDoc(usersCollectionRef, { name: newName, age: Number(newAge) });
  };

*/
    return (
        <div className=" fixed top-0 right-0 bottom-0 left-0 bg-none flex items-center justify-center ">
            <div className="sm:w-[50%] max-w-[500px] bg-[#000000] rounded-[20px] border-2 relative border-white shadow-custom">
                <button onClick={onClose} className="absolute right-[3px] top-[8px] bg-none">
                    <img src="/images/close.png" alt="close" />
                </button>
                <div className={"p-[30px] border-b-[2px text] text-white"}>
                    <h3 className="mb-[5px]">
                        Enter the motion to be disscussed
                    </h3>
                    <input 
                        className='bg-[#ffffff] border-none  text-black text-[18px] rounded-[5px] outline-none w-[90%]'
                        fullwidth="true"
                        value={topic}
                        maxLength={60}
                        onChange={(e) => setTopic(e.target.value)
                            
                                         
                            
                }
                    />
              

                    
                    <h2 className="text-[18px] m-[10px] font-bold text-white">Room types</h2>
                    <div className="grid grid-cols-3 gap-[30px]">
                        <div
                            onClick={() => setRoomType('public')}
                            className ={"flex-col flex items-center p-[10px] rounded-xl  cursor-pointer text-white " +( roomType === 'public' ? "bg-[#525252]" : '' )}
                               
                            
                        >
                            <FaGlobeAfrica className='text-[50px] hover:text-[75px] transition-all' />
                            <span>Public</span>
                        </div>
                       
                        <div
                            onClick={() => setRoomType('private')}
                            className ={"flex-col flex items-center p-[10px] rounded-xl cursor-pointer text-white" +( roomType === 'private' ? "bg-[#525252]" : '' )}
                        >
                            <HiLockClosed className='text-[50px] hover:text-[75px] transition-all' />
                            <span>Private</span>
                        </div>
                    </div>
                </div>
                <div className="p-[30px] text-center">
                    <h2 className="font-bold text-[20px] text-white mb-[20px]">Start a room, open to everyone</h2>
                    <button
                        onClick={createRoomDoc}
                        className="bg-[#ffffff] text-black flex items-center w-[200px] justify-center p-[7px] rounded-[20px] m-0 transition-all ease-in-out hover:text-xl" 
                    >
                         
                        <span className='ml-[5px] font-bold'>Start Room</span>
                    </button>
                </div>
            </div>
        </div>
    );
};



export default AddRoomModal;

