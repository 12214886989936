
import live from "../icons/live.svg";


const LiveIcon = () => {

  return (
  <div className=" flex align-middle m-3 h-5 w-5 bg-black  text-white">   
    <img src={live} className="h-9 w-9 mx-1" /> Live
  </div>);
};




export default LiveIcon;