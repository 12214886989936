import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LISTENER } from '../../App';
import LiveIcon from '../LiveIcon';
import { UserAuth } from '../../contexts/AuthContext';

import { doc, getDoc,getDocs, setDoc, collection } from 'firebase/firestore';
import { db } from '../../firebase.config';


import { useCallState } from '../../CallProvider';

//On line 17 we're using conditional styling for whether there is only one speaker more than one
// I may have been a complete idiot here, I could have made creating rooms such that it first cretaes a doc, gets the doc.id then uses the doc.id as the roomName for the api call to daily.co
//I could have then just mapped the data from the doc directly, hopefully the method I'm using works well too

const roomsCollectionRef = collection(db, "rooms");









    


  const  RoomCard  =  ({ room }) => {

    
    
    
      const [documentData, setDocumentData] = useState({});
  
    useEffect(() => {
      const searchDocument = async () => {
        try {
          const documentRef = doc(db, 'rooms', room?.name);
          const documentSnapshot = await getDoc(documentRef);
  
          if (documentSnapshot.exists()) {
            const data = documentSnapshot.data();
            setDocumentData(data);
          } else {
            console.log('Document does not exist');
          }
        } catch (error) {
          console.error('Error searching for document:', error);
        }
      };
  
      searchDocument();
    }, []);
 
    
    const addParticipantToRoom = async (roomId, participantData) => {
      try {
        // Create a reference to the "rooms" collection and the specific room document
        const roomRef = doc(db, 'rooms', roomId);
    
        // Create a reference to the "participants" subcollection within the room document
        const participantsRef = collection(roomRef, 'participants');
    
        // Use the user ID as the document ID for the participant data
        await setDoc(doc(participantsRef, participantData.userId), participantData);
        console.log('Participant added successfully.');
      } catch (error) {
        console.error('Error adding participant:', error);
      }
    };

const { user, logout } = UserAuth();
    const roomref = doc(db, "rooms", room?.name )

    const participantsRef = collection(roomref, 'participants');
    

    const [ParticipantsData, setParticipantsData] = useState([]);




    useEffect(() => {
      const getAllParticipants = async () => {
          const data = await getDocs(participantsRef);
          setParticipantsData(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
          
      };

      getAllParticipants();
      
  }, []);

    const participantData = {
       // Replace with the actual user ID
      name: 'John Doe',
      role: 'LISTENER',
      // Add other participant data as needed
    };

    




    const { joinRoom, participants} = useCallState()||[];
    
    const navigate = useNavigate();
    const handleJoinRoom =()=>{

        

        let userName = user.displayName
        let name = room?.name
        let moderator = true
         
    //The moderator property maybe subject to change when the realtime database is up and running.

    
        userName = `${userName?.trim()}_${LISTENER}`
        
        joinRoom({userName, name, moderator})
        navigate(`/rooms/room/${name}`)
};{}
    
    
    
  
    return (
        <div
            onClick={handleJoinRoom}
            className={" shadow-custom sm:w-[450px] sm:h-[210px] flex-col border-2 justify-center m-3 cursor-pointer text-2xl font-extrabold border-white text-white bg-black"} 
          ><div className=' border-b-2 border-b-white py-1'>
          <LiveIcon/>
          
          
          </div>
        <div className='flex place-self-start sm:pt-[30px]'>
            <h1 className={"  flex m-3 text-center text-[27x]  font-extrabold text-white  transition-all"}>{documentData.topic}</h1>
            
        </div> 
            
  
           
                  <div className='flex text-xl text-right m-3 sm:mt-[33px] justify-end '> <span>{ParticipantsData?.length} Participants</span> </div>
        </div>
    );
  }




export default RoomCard;


//function FeaturedRoomCard(){
//    return(
//    <Link className="h-48 w-96 bg-black border-1 fixed flex border-gradient-to-r from-indigo-500 to-purple-500 rounded-lg text-2xl text-white font-extrabold" path = '/'> Pineapple Belongs on Pizza
//   </Link>
//    );
//};

