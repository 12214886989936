
import React from 'react';

import {Routes, Route } from 'react-router-dom';
import Account from './components/Account';
import { Analytics } from '@vercel/analytics/react';
import Signin from './components/SignIn';
import Signup from './components/SignUp';
import { AuthContextProvider } from './contexts/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import Rooms from './Pages/Rooms';
import Room from './Pages/Room';
import InCall from './components/InCall';
import Home from './Pages/Home';
import { CallProvider, LOADING, useCallState } from './CallProvider';
import LoadingScreen from './components/LoadingScreen';



import Navbar from './components/Navbar';
import AccountPage from './Pages/AccountPage';



/*const ROOM_ID = "<%= roomId %>"*/

export const MOD = "MOD";
export const SPEAKER = "SPK";
export const LISTENER = "LST";


function App() {

  const { view } = useCallState();
  return (
      


    <div className='bg-black overflow-y-auto'>
      <Analytics/>
      <AuthContextProvider>
   
      <Navbar/>
        
        
        {view === LOADING && <LoadingScreen />}
          
        <Routes>
        <Route path='/loading' element={<LoadingScreen/>} />
          <Route path='/' element={<Home />} />
          <Route path='/signin' element={<Signin />} />
          

          <Route path='/signup' element={<Signup />} />
          <Route
            path='/account'
            element={
              <ProtectedRoute>
                <AccountPage />
              </ProtectedRoute>
            }
          />
          <Route
            path='/rooms'
            element={
              <ProtectedRoute>
                <Rooms />
              </ProtectedRoute>
            }
          />
          <Route
            path='/rooms/room/:id'
            element={
              <ProtectedRoute>
                <InCall />
              </ProtectedRoute>
            }
          />
           
        </Routes>
        
      </AuthContextProvider>
      
    </div>


    
  );
};



export default App;