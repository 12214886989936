import React, { useState } from 'react';
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../firebase.config';

const Poll = ({ roomId }) => {
  // Step 1: Initialize State
  const [userVote, setUserVote] = useState(null);
  const [pollResults, setPollResults] = useState({ yes: 0, no: 0 });
  const [yesVotes, setYesVotes] = useState(0);
  const [noVotes, setNoVotes] = useState(0);
  const [totalVotes, setTotalVotes] = useState(0);
  const [documentData, setDocumentData] = useState({});




  const searchDocument = async () => {
    try {
      const documentRef = doc(db, 'rooms', roomId);
      const documentSnapshot = await getDoc(documentRef);

      if (documentSnapshot.exists()) {
        const data = documentSnapshot.data();
        setDocumentData(data);
      } else {
        console.log('Document does not exist');
      }
    } catch (error) {
      console.error('Error searching for document:', error);
    }
  };










  const handleVote = (voteType) => {
    if (userVote !== null) {
      // User has already voted
      alert('You have already voted.');
      return;
    }
    // Update the total votes count
    setTotalVotes(totalVotes + 1);

    // Update the specific vote count
    if (voteType === 'yes') {
      setYesVotes(yesVotes + 1);
    } else if (voteType === 'no') {
      setNoVotes(noVotes + 1);
    }
    setUserVote(1);
  };

  // Calculate the percentages
  const yesPercentage = ((yesVotes / totalVotes) * 100).toFixed(2);
  const noPercentage = ((noVotes / totalVotes) * 100).toFixed(2);

  // Step 2: Vote Handling Function
  
  return (
    
    <div className="  w-[50%] max-w-[500px] items-center bg-[#ffffff] rounded-[20px] relative border-[1px] border-black shadow-xl p-3 text-xl font-extrabold dark:border-white dark:bg-black dark:text-white ">
      <h2>Do you agree?</h2>

      {/* Step 4: Conditional Rendering */}
      {userVote === null ? (
        // User has not voted yet, show voting options
        <div className=" flex flex-col grid-cols-3 p-[30px] text-white dark:text-black  " >
          <button  className='bg-white text-black border-[1px] border-black rounded-lg m-2' onClick={() => handleVote('yes')}>Yes</button>

          <button className='bg-white text-black border-[1px] border-black rounded-lg m-2' onClick={() => handleVote('no')}>No</button>
        </div>
      ) : (
        // User has voted, show poll results
        <div className=" flex flex-col grid-cols-3 p-[30px] text-white dark:text-black font-bold ">
          <p>Your Vote: {userVote}</p>
          <p>Results:</p>
          <p className='bg-white text-black border-[1px] border-black rounded-lg m-2'>Yes: {yesPercentage}%</p>
          <p className='bg-white text-black border-[1px] border-black rounded-lg m-2'>No: {noPercentage}%</p>
        </div>
      )}</div>
    
  );
};

export default Poll;
