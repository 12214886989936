import React, { useEffect, useState, useMemo, useRef } from "react";

import { useCallState } from "../CallProvider";
import { LISTENER, MOD, SPEAKER } from "../App";
import useClickAway from "../useClickAway";
import MicIcon from "./MicIcon";
import MutedIcon from "./MutedIcon";
import MoreIcon from "./MoreIcon";
import Menu from "./Menu";


import { UserAuth } from "../contexts/AuthContext";





const ADMIN_BADGE = "⭐ ";



const initials = (name) =>
  name
    ? name
        .split(" ")
        .map((n) => n.charAt(0))
        .join("")
    : "";

const Participant = ({ roomId,  participant,  local, modCount }) => { 


  const [Username, setUsername]= useState([]);


  

  const { user }= UserAuth();
  
  
  
  const {
    getAccountType,
    activeSpeakerId,
    changeAccountType,
    changeSpeakerType,
    getParticipantData,
    participants,
    displayName,
    handleMute,
    handleUnmute,
    removeFromCall,
    lowerHand,
    raiseHand,
    leaveCall,
    endCall,
  } = useCallState();



    
    
  
   
  







  const audioRef = useRef(null);
  const { ref, isVisible, setIsVisible } = useClickAway(false);

 
 const thisname = "John Doe"   

  const name = displayName(participant?.user_name);

  const {isProposer, setisProposer} = useState(true);
  
  const menuOptions = useMemo(() => {
    const mutedText = participant?.audio ? "Mute" : "Unmute";

    const audioAction = participant?.audio
      ? (id) => handleMute(id)
      : (id) => handleUnmute(id);

    /**
     * Determine what the menu options are based on the account type.
     * Listeners can't unmute but can raise their hand to speaker.
     * Moderators can change the status of others but can't have their
     * own status change to speaker or listener.
     * Moderators cannot unmute but can mute.
     */
    let options = [];

    /**
     * If it's the local particpant's menu:
     *  - Mods can unmute themselves and speakers.
     *  - Speakers can unmute themselves.
     *  - Listeners listen. :)
     */
    if (
      participant?.local &&
      [MOD, SPEAKER].includes(getAccountType(participant?.user_name))
    ) {
      options.push({
        text: mutedText,
        action: () => audioAction(participant),
      });
    }
    if (
      participant?.local && getAccountType(participant?.user_name) === SPEAKER)
      {
        options.push({
          text: "Be Proposer",
          action: () => changeSpeakerType(roomId, participant?.user_id, true),
        },
        {
          text: "Be Opposer",
          action: () => changeSpeakerType(roomId, participant?.user_id, false),
        },
        );

      }

    /**
     * If it's a remote participant:
     * Mods can only MUTE someone. We don't want
     * people getting unmuted without knowing because
     * it can be a bit invasive 😬
     */
    if (
      !participant?.local &&
      participant?.audio &&
      getAccountType(local?.user_name) === MOD &&
      [MOD, SPEAKER].includes(getAccountType(participant?.user_name))
    ) {
      options.push({
        text: "Mute",
        action: () => handleMute(participant),
      });
    }

    switch (getAccountType(participant?.user_name)) {
      case SPEAKER:
        if (!participant?.local) {
          const o = [
            {
              text: "Make moderator",
              action: () => changeAccountType(roomId, participant, MOD),
            },
            {
              text: "Make listener",
              action: () => changeAccountType(roomId, participant, LISTENER),
            },
            {
              text: "Remove from call",
              action: () => removeFromCall(participant),
              warning: true,
            },
            
          
           
          ];
          options = [...options, ...o];
        }
        break;
      case LISTENER:
        if (participant?.local) {
          options.push({
            text: participant?.user_name.includes("✋")
              ? "Lower hand"
              : "Raise hand ✋",
            action: participant?.user_name.includes("✋")
              ? () => lowerHand(participant)
              : () => raiseHand(participant),
          });
        } else {
          const o = [
            {
              text: "Make moderator",
              action: () => changeAccountType(roomId, participant, MOD),
            },
            {
              text: "Make speaker",
              action: () => changeAccountType(roomId, participant, SPEAKER),
            },
            {
              text: "Remove from call",
              action: () => removeFromCall(participant),
              warning: true,
            },
          
          ];
          options = [...options, ...o];
        }
        break;
      default:
        break;
    }

    /**
     * Let the local participant leave. (There's also
     * a button in the tray.) "Leave" or "Remove" should
     * be the last items
     */
    if (participant?.local) {
      const lastMod =
        modCount < 2 && getAccountType(participant?.user_name) === MOD;
      options.push({
        text: lastMod ? "End call" : "Leave call",
        action: () => (lastMod ? endCall() : leaveCall(participant)),
        warning: true,
      });
    }

    return options;
  }, [
    participant,
    local,
    getAccountType,
    changeAccountType,
    changeSpeakerType,
    handleMute,
    handleUnmute,
    removeFromCall,
    endCall,
    lowerHand,
    leaveCall,
    modCount,
    raiseHand,
  ]);

  useEffect(() => {
    if (!participant?.audioTrack || !audioRef.current) return;
    // sanity check to make sure this is an audio track
    if (
      participant?.audioTrack?.track &&
      !participant?.audioTrack?.track?.kind === "audio"
    )
      return;
    // don't play the local audio track (echo!)
    if (participant?.local) return;
    // set the audio source for everyone else

    /**
      Note: Safari will block the autoplay of audio by default.

      Improvement: implement a timeout to check if audio stream is playing
      and prompt the user if not, e.g:
      
      let playTimeout;
      const handleCanPlay = () => {
        playTimeout = setTimeout(() => {
          showPlayAudioPrompt(true);
        }, 1500);
      };
      const handlePlay = () => {
        clearTimeout(playTimeout);
      };
      audioEl.current.addEventListener('canplay', handleCanPlay);
      audioEl.current.addEventListener('play', handlePlay);
     */
    audioRef.current.srcObject = new MediaStream([participant?.audioTrack]);
  }, [participant?.audioTrack, participant?.local]);

  useEffect(() => {
    // On iOS safari, when headphones are disconnected, all audio elements are paused.
    // This means that when a user disconnects their headphones, that user will not
    // be able to hear any other users until they mute/unmute their mics.
    // To fix that, we call `play` on each audio track on all devicechange events.
   
    //I have removed the return false because I'm getting errors we'll see what the implications are; 
    if (!audioRef.current) {
      return () => {}
    }

    const startPlayingTrack = () => {
      audioRef.current?.play();
    };
    navigator.mediaDevices.addEventListener("devicechange", startPlayingTrack);

    return () => {
      navigator.mediaDevices.removeEventListener(
        "devicechange",
        startPlayingTrack
      );
    };
  }, [audioRef]);

  const showMoreMenu = useMemo(
    () => getAccountType(local?.user_name) === MOD || participant?.local,
    [getAccountType, local, participant]
  );

  return (
    <div className=" flex flex-col m-3  items-start relative max-w-[104px] "> 
  
      <div className="w-[70px] h-[70px] sm:w-[80px] sm:h-[80px] rounded-[24px]  flex items-center justify-center border-[2px] border-solid  shadow-2xl border-white bg-black "
        muted={!participant?.audio}
        isActive={activeSpeakerId === participant?.user_id}
      >
        <p className=" font-extrabold text-base sm:text-lg leading-[32px] text-white "> 
          {participant?.owner ? ADMIN_BADGE : ""}
          {user?.photoUrl ? (
        <img src={user.photoUrl} alt="Profile Picture" className="h-full w-full rounded-full object-cover" />
      ) : (
        initials(name)
      )}
        </p>
      </div>
      <p className=" m-[8px] font-semibold text-sm sm:text-base pl-[4px] max-w-[80px] whitespace-nowrap overflow-hidden text-ellipsis leading-[20px] text-white ">{name}</p>
      {getAccountType(participant?.user_name) !== LISTENER && (
        <div className="absolute top-[43px] sm:top-[52px] left-[-4px]">
          {participant?.audio ? <MicIcon /> : <MutedIcon />}
        </div>
      )}
      {showMoreMenu && menuOptions.length > 0 && (
        <button className="border-none bg-transparent absolute top-[43px] sm:top-[52px] right-[10px] sm:right-[-4px] p-0 cursor-pointer" onClick={() => setIsVisible(!isVisible)}>
          <MoreIcon />
        </button>
      )}
      {isVisible && (
        <div className="absolute bottom-0 right-0 z-10" ref={ref}>
            <Menu options={menuOptions} setIsVisible={setIsVisible} />
        </div>
      )}
      {participant?.audioTrack && (
        <audio
          autoPlay
          playsInline
          id={`audio-${participant.user_id}`}
          ref={audioRef}
        />
      )}
    </div>
  );
};

 export default Participant;// React.memo(Participant, (p, n) => p.participant?.id === n.participant?.id);