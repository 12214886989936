import { useState, useEffect } from "react";
import { getDoc, doc } from "firebase/firestore";
import { db } from "../firebase.config";















  const CopyLinkBox = ({ roomId }) => {



    const [documentData, setDocumentData] = useState({});
  
    useEffect(() => {
      const searchDocument = async () => {
        try {
          const documentRef = doc(db, 'rooms', roomId);
          const documentSnapshot = await getDoc(documentRef);
  
          if (documentSnapshot.exists()) {
            const data = documentSnapshot.data();
            setDocumentData(data);
          } else {
            console.log('Document does not exist');
          }
        } catch (error) {
          console.error('Error searching for document:', error);
        }
      };
    
      searchDocument();
    }, []);
 
    
  const [linkCopied, setLinkCopied] = useState(false);
  return (
    <div className =" flex flex-col items-center mb-[-50px] max-w-[450px] ml-auto mr-auto my-[-45px] z-20 ">
      <div className="border-2 border-solid rounded-lg flex flex-col justify-center items-center p-[15px] bg-black shadow-custom border-white">
        <h3 className=' text-xl sm:text-2xl  font-extrabold text-white'>{documentData.topic}</h3>
        <p className='justify-center font-bold text-center text-white'>
          Copy and share join code with others to invite them. Code:{" "}
          <span className="">here</span>
        </p>
        <button className=""
          onClick={() => {
            navigator.clipboard.writeText(roomId);
            setLinkCopied(true);
            setTimeout(() => setLinkCopied(false), 5000);
          }}
        >
          <span className="items-center font-semibold text-white ">
            {linkCopied ? "Copied!" : `Copy join code`}
          </span>
        </button>
      </div>
    </div>
  );
};
export default CopyLinkBox;