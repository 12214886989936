import {React } from "react";
import { useState } from "react";
import useClickAway from "../useClickAway";
import { Link } from "react-router-dom";
import Logo from "./Logo";
import { UserAuth } from '../contexts/AuthContext';
import { useNavigate } from "react-router-dom";
import { FaBars } from 'react-icons/fa'
import { BiSolidHome } from 'react-icons/bi';
import {MdOutlineAccountCircle} from 'react-icons/md'
import {BsBroadcastPin} from 'react-icons/bs'
import { FaMoon, FaSun } from 'react-icons/fa';
import useDarkMode from "./Hooks/useDarkMode";


const Navbar = () => {
  const { user, logOut } = UserAuth();
  
  const [sidebar, setSidebar] = useState(false);
  const { ref, isVisible, setIsVisible } = useClickAway(false);

  const showSidebar = () => setIsVisible(!isVisible);

  const SideNavbar = () => {
    const navigate = useNavigate();
  
    return(
  
        <div className= "" >
  
            <Link to='/' onClick={showSidebar}>
                 <SideBarIcon icon={<BiSolidHome size="28" />} text ='Home' />
            </Link>
  
            <Link to='/rooms' onClick={showSidebar}>
                <SideBarIcon icon={<BsBroadcastPin size="28" />} text ='Live Rooms'  /></Link>
  
            <Link to='/account' onClick={showSidebar}>
            <SideBarIcon icon={<MdOutlineAccountCircle size="28" />} text ='Account'  />
            </Link>
  
        </div>
    );
  
  
  }
  const SideBarIcon = ({ icon, text = 'tooltip 💡' }) => (
    <div className="sidebar-icon group" >
      {icon}
      <span class="sidebar-tooltip group-hover:scale-100">
        {text}
      </span>
    </div>
  );

  const ThemeIcon = () => {
    const [darkTheme, setDarkTheme] = useDarkMode();
    const handleMode = () => setDarkTheme(!darkTheme);
    return (
      <span onClick={handleMode}>
        {darkTheme ? (
          <FaSun size='24' className='top-navigation-icon' />
        ) : (
          <FaMoon size='24' className='top-navigation-icon' />
        )}
      </span>
    );
  };

  
  
  

  const initials = (name) =>
  name
    ? name
        .split(" ")
        .map((n) => n.charAt(0))
        .join("")
    : "";

  const handleSignOut = async () => {
    try {
      await logOut()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className='flex fixed justify-between top-0 border-b-[1px]  w-full h-[72px] sm:h-[90px] place-items-center bg-black border-b-white text-white '>
      <div className="flex ml-[2rem] bg-none text-[2rem] items-center">
      <FaBars  className="sm:mx-3 " onClick={showSidebar} /> 
      <Logo/>
       <h1 className='text-center text-xl sm:mx-3 mx-1 sm:text-3xl font-bold p-2'>
        Discourse
      </h1>
       </div>
    
  
  <div>
  
    
      </div>
      <div ref={ref} >
        
      <div className={ isVisible ? 'nav-menu active' : 'nav-menu'}>
      
      <SideNavbar />
     
      </div> 
      
      </div>
      
      {user? (
        <div className="flex p-3 place-items-center ">
          
        <button className="p-2 sm:px-7 font-semibold sm:text-lg" onClick={handleSignOut}>Logout</button>
        
        <Link className=" flex rounded-3xl h-12 w-12 border-2 shadow-md text-center place-items-center justify-center border-white dark:border-[1px] " to = "/account"> {initials(user?.displayName)}</Link>

        </div>

      ) : (
        <Link className="p-3 m-3" to='/signin'>Sign in</Link>
      )}
    </div>
  );
};



export default Navbar;


  