import React
 from "react";
import DotLoader from "react-spinners/DotLoader"
import logo from "../icons/Logo.png";
import { useCallState } from "../CallProvider";




function LoadingScreen(){
    const { isloading } = useCallState();
    const LogoIcon = ({ icon }) => (
        <div className="h-16 w-16" >
          {icon}
        </div>
      );
    return(
        <div className="w-[100%] h-[93.3%] flex justify-center items-center bg-black ">
         <img src={logo} className="h-[210px] w-auto"></img>
        </div>
    )
} 
export default LoadingScreen;