const theme = {
    colors: {
      
      black: "#000000",
      blue: "#1f2d3d",
      blueDark: "#121a24",
      blueLight: "#2b3f56",
      cyan: "#00c9df",
      cyanDark: "#00a4b7",
      cyanLight: "#e2fbfd",
      green: "#72cc18",
      greenDark: "#62a60f",
      greenLight: "#eefae0",
      grey: "#c8d1dc",
      greyDark: "#6b7785",
      greyLight: "#e6eaef",
      greyLightest: "#f7f9fa",
      orange: "#fb651e",
      orangeDark: "#d48200",
      orangeLight: "#fef2d8",
      red: "#e71115",
      redDark: "#bb0c0c",
      redLight: "#fddddd",
      white: "#ffffff",
      turquoise: "#1bebb9",
      teal: "#14997a",
    },
    fontSize: {
      small: "11px",
      base: "12px",
      med: "14px",
      large: "16px",
      xlarge: "28px",
      xxlarge: "32px",
    },
    fontFamily: {
      regular: "Helvetica, Arial, sans-serif",
    },
  };
  
  export default theme;