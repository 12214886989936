import logo from "../icons/Logo.png";
import styled from "styled-components";


const Logo = () => {
    return <Icon src={logo} className=' relative flex h-[51px] sm:h-[63px] ml-[15px] mt-[-1px]' />;
  };
  

const Icon = styled.img``;

export default Logo;