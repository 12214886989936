import react from 'react'
import { useState } from 'react';
import {  
    collection,     
    doc,
    addDoc   
} from "firebase/firestore";
import { db } from "../firebase.config";
import { UserAuth } from '../contexts/AuthContext';


const Feedback = ({ onClose }) => {
    const [feedback, setFeedback] = useState('');
    const [isSent, setIsSent] = useState(false);
    const {user }= UserAuth();
  

    const logFeedback = async () => {
        const data ={
            feedback: feedback,
            user: user?.email
        }
        const doc = collection(db, "feedback");
    try {
      await addDoc(doc, data);
      console.log("Document successfully written!");
      setIsSent(true);
      // You can return a success message or any other relevant data here
    } catch (error) {
      console.error("Error writing document: ", error);
      // Handle the error here, you can throw or return an error message
    }
      };



    return(
       
        <div className=" fixed top-0 right-0 bottom-0 left-0 bg-none flex items-center justify-center "> 
        
        <div className="sm:w-[50%] max-w-[500px] bg-[#000000] rounded-[20px] border-2 relative border-white shadow-custom">
            {!isSent ? (
            <>
            <button onClick={onClose} className="absolute right-[3px] top-[8px] bg-none">
                <img src="/images/close.png" alt="close" />
            </button>
            <div className={"p-[30px] border-b-[2px text] text-white"}>
                <h3 className="mb-[5px]">
                    Help us improve, any issues while using the app?
                </h3>
                <input 
                    className='bg-[#ffffff] border-none  text-black text-[18px] rounded-[5px] outline-none w-[90%] h-auto'
                    fullwidth="true"
                    value={feedback}
                    maxLength={300}
                    onChange={(e) => setFeedback(e.target.value)
                        
                                     
                        
            }
                />
          

                
        
            </div>
            <div className="p-[30px] text-center">
             
                <button
                    onClick={logFeedback}
                    className="bg-[#ffffff] text-black flex items-center w-[200px] justify-center p-[7px] rounded-[20px] m-0 transition-all ease-in-out hover:text-xl" 
                >
                     
                    <span className='ml-[5px] font-bold'>Send</span>
                </button>
            </div> 
            </>
             ) :  (
                <div className='h-[200px]  items-center px-3 flex justify-center font-semibold '>
                     <button onClick={onClose} className="absolute right-[7px] top-[11px] bg-none">
                <img src="/images/close.png" alt="close" />
            </button>
                <h1 className='text-2xl'>Thank you for your feedback!</h1>
                </div>
            )}
           
        </div>
      
    </div>)

    
}
export default Feedback;