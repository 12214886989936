
import { React } from 'react';
import { Link } from 'react-router-dom';
import logo from "../icons/Logo.png";
import { useState } from 'react';
import { BsChatLeftText } from "react-icons/bs";
import Feedback from '../components/Feedback';




function Home() {
  const [showModal, setShowModal] = useState(false);

  const SideBarIcon = ({ icon, text = 'tooltip 💡' }) => (
    <div class="feedback-icon group" >
      {icon}
      <span class="feedback-tooltip group-hover:scale-100">
        {text}
      </span>
    </div>
  );



  function openModal() {
    setShowModal(true);
}
    return ( 
    
    <div className="flex flex-col sm:flex-row-reverse sm:place-items-center sm:justify-center shadow-md h-screen text-white bg-black ">
      <div className=' flex justify-center mt-7  '>
      <img alt='discourse-brain-logo' src={logo} className='h-[300px] w-[300px] p-9 sm:h-[900px] sm:w-[900px] '></img> 
      </div>
      <div className='flex flex-col content-center justify-center max-w-3xl p-3 '>
        <h1 className='text-4xl  sm:text-5xl font-bold antialiased text-center font-inter'>A platform for debate and the sharpening of the mind.</h1>
           <h2 className=' flex text-gray-400 text-2xl align-middle w-[410px] p-5 font-semibold font-inter place-self-center text-center'>Engage in interactive and meaningful debates on cultured topics through live audio.</h2>
         <div className='flex flex-row justify-center p-9'>
          <Link className='flex border-2 items-center border-white p-1 sm:p-2  text-lg font-bold  shadow-lg  hover:border-white hover:text-white hover:bg-black text-black bg-white transition-[500ms]  m-1 ' to='/rooms'>Join/CreateRoom</Link>
          <a className='flex  border-2 p-1 sm:p-2 items-center border-white  text-lg font-bold shadow-lg text-white bg-black hover:bg-white  hover:text-black transition-[500ms]  m-1' href ='https://discourseco.wordpress.com/2022/08/31/what-we-are-about/'>Learn More</a>
         </div> 
         <button
                            onClick={openModal}
                            className={"fixed items-center justify-center bottom-4 right-4 bg-white px-3 py-3  rounded-[100%] h-[81px] w-[81px]   text-white hover:text-lg transition-all border-black border-2"}
                        > <SideBarIcon icon ={<BsChatLeftText size={39} className="fill-black"/>} text='Anything go wrong? 
                        Tell us.'  />                        
                        </button> 

         
         </div>    
         
         {showModal && <Feedback onClose={() => setShowModal(false)} />}               
                
    </div>
    );
     /*
      <div className=" ">



        
        <Slider/>
        <Footer/>
      
    
        
      </div>

*/      
        
  };
  
  export default Home;
  