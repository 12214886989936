// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import 'firebase/database'
import { getDatabase } from "firebase/database";
import {
  getFirestore,
  
} from 'firebase/firestore'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAcP62kEpoulWUU24otZxybuigW1Z94J_s",
  authDomain: "user-auth-eb509.firebaseapp.com",
  projectId: "user-auth-eb509",
  storageBucket: "user-auth-eb509.appspot.com",
  messagingSenderId: "354191171696",
  appId: "1:354191171696:web:1ad062d41bbd9fc7a694b5",
  measurementId: "G-ZP7XJZM10F"
};






// Initialize Firebase

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const database = getDatabase(app);
