import React, { useState, useEffect } from 'react';
import AddRoomModal from '../components/AddRoomModal';
import RoomCard from '../components/Roomcard/RoomCard';
import { db } from "../firebase.config";
import{ AiOutlinePlus } from 'react-icons/ai'

import {
  collection,
  getDocs,
  
} from "firebase/firestore";
import { useCallState } from "../CallProvider";
import { UserAuth } from '../contexts/AuthContext';


//import { getAllRooms } from '../http';



const roomsCollectionRef = collection(db, "rooms");

   
// const rooms = [
//     {
//         id: 1,
//         topic: 'Which framework best for frontend ?',
//         speakers: [
//             {
//                 id: 1,
//                 name: 'John Doe',
//                 avatar: '/images/monkey-avatar.png',
//             },
//             {
//                 id: 2,
//                 name: 'Jane Doe',
//                 avatar: '/images/monkey-avatar.png',
//             },
//         ],
//         totalPeople: 40,
//     },
//     {
//         id: 3,
//         topic: 'What’s new in machine learning?',
//         speakers: [
//             {
//                 id: 1,
//                 name: 'John Doe',
//                 avatar: '/images/monkey-avatar.png',
//             },
//             {
//                 id: 2,
//                 name: 'Jane Doe',
//                 avatar: '/images/monkey-avatar.png',
//             },
//         ],
//         totalPeople: 40,
//     },
//     {
//         id: 4,
//         topic: 'Why people use stack overflow?',
//         speakers: [
//             {
//                 id: 1,
//                 name: 'John Doe',
//                 avatar: '/images/monkey-avatar.png',
//             },
//             {
//                 id: 2,
//                 name: 'Jane Doe',
//                 avatar: '/images/monkey-avatar.png',
//             },
//         ],
//         totalPeople: 40,
//     },
//     {
//         id: 5,
//         topic: 'Artificial inteligence is the future?',
//         speakers: [
//             {
//                 id: 1,
//                 name: 'John Doe',
//                 avatar: '/images/monkey-avatar.png',
//             },
//             {
//                 id: 2,
//                 name: 'Jane Doe',
//                 avatar: '/images/monkey-avatar.png',
//             },
//         ],
//         totalPeople: 40,
//     },
// ];

function Rooms() {
    const [width, setWidth] = useState(window.screen.width);
    const [showModal, setShowModal] = useState(false);
    const [rooms, setRooms] = useState([]);
    const [room, setRoom] = useState([]);
    const {  } = useCallState();
    
    const user = UserAuth();
    const userName = user.id;

    window.addEventListener("resize", () => {
        setWidth(window.screen.width);
      });

     
       

        
   useEffect(()=>{
    const getRooms = async () =>{
        const response = await fetch(
            // CHANGE THIS TO YOUR NETLIFY URL
            // EX: https://myapp.netlify.app/.netlify/functions/room
            `${
              process.env.REACT_APP_VERCEL_URL || "http://localhost:8800"
            }/rooms`,
            {
              method: "GET",
            }
          ).catch((err) => {
            throw new Error(err);
          });
          const roomdata = await response.json();
          setRoom(roomdata.data);
          
          return room;
          
          
    } 
    
    getRooms();
    
   },[])  
  
console.log(room);
   

    useEffect(() => {
        const getAllRooms = async () => {
            const data = await getDocs(roomsCollectionRef);
            setRooms(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
            
        };

        getAllRooms();
        
    }, []);
   
    function openModal() {
        setShowModal(true);
    }
    return (
        <div className="bg-black text-white  pt-24 odd:border-black h-screen" >
            
            <div className="  bg-black border-2 border-black ">
                <div className="flex items-center justify-between m-[20px]  ">
                    <div className="flex items-center">
                        <span className=" relative font-extrabold after:content-[''] after:absolute after:bottom-[-10px] after:left-0 after:w-[60%] z-0 after:h-[4px] after:bg-white text-white">All debate rooms</span>
                        <div className="bg-[#000000] ml-5 flex items-center p-3 min-w-[150px] rounded-[20px] border-white border-2">
                            <img src="/images/search-icon.png" alt="search" className="text-white" />
                            <input type="text" className="bg-transparent border-none outline-none p-[10px] text-white w-[100%]" />
                        </div>
                    </div>

                    <div className={"flex items-center "}>
                        {width > 768 ? (
                        <button
                            onClick={openModal}
                            className={"flex items-center bg-[#000000] px-3 py-1 sm:p-[20px] rounded-[20px] sm:ml-[10px] text-white hover:text-lg transition-all border-white border-2"}
                        >  
                            <img 
                                src="/images/add-room-icon.png"
                                alt="add-room" />
                            <span className='font-extrabold ml-[16px]'>Start a room</span>
                       
                        
                        
                        </button> 
                        ) : ( <button
                            onClick={openModal}
                            className={"fixed items-center justify-center bottom-3 right-3 bg-white px-2 py-2  rounded-[100%]   text-white hover:text-lg transition-all border-black border-2"}
                        > <AiOutlinePlus size={39}  className='fill-black '/>
                           
                        
                        </button> 


                        )}
                    </div>
                </div>


          <div > 
            
            <div className='grid grid-cols-1 gap-3 sm:grid-cols-3 justify-center overflow-auto border-2 border-black'>
            {room.map((room) => (
              
                
                
               
                    

                    <RoomCard room={room} key={room.id} />
                    
                 
              ))} 
               </div>
            </div>
        </div>
            {showModal && <AddRoomModal onClose={() => setShowModal(false)} />}
            
        </div>
    );
}

export default Rooms;
